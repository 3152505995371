import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
	{ // 登录
		path: '/signin',
		name: 'Signin',
		component: () => import("../appviews/Signin.vue")
	},
	/* 供应商端 */
	{ // 供应商注册
		path: '/registry',
		name: 'Registry',
		component: () => import("../appviews/supplier/Registry.vue")
	},
	{ // 供应商缴费
		path: '/pay',
		name: 'Pay',
		component: () => import("../appviews/supplier/Pay.vue")
	},
	{ // 应用主页(子页面统一增加s-开头用于区分所属端)
		path: '/s-index',
		name: 'SupplierIndex',
		component: () => import("../appviews/supplier/Index.vue"),
		children: [
			{ // 首页
				path: '/s-home',
				name: 'SupplierHome',
				component: () => import("../appviews/supplier/Home.vue")
			},
			{ // 通知待办
				path: '/s-notice',
				name: 'SupplierNotoce',
				component: () => import("../appviews/common/Notice.vue")
			},
			{ // 通知详情
				path: '/s-notice-detail',
				name: 'SupplierNotoceDetail',
				component: () => import("../appviews/common/NoticeDetail.vue")
			},
			{ // 账号管理
				path: '/s-account',
				name: 'SupplierAccount',
				component: () => import("../appviews/common/Account.vue")
			},
			{ // 项目列表
				path: '/s-project',
				name: 'SupplierProject',
				component: () => import("../appviews/supplier/Inquiry.vue")
			},
			{ // 询价单
				path: '/s-inquiry',
				name: 'SupplierInquiry',
				component: () => import("../appviews/supplier/Inquiry.vue")
			},
			{ // 报价单
				path: '/s-offer',
				name: 'SupplierOffer',
				component: () => import("../appviews/supplier/Offer.vue")
			},
			{ // 缴费记录
				path: '/s-paylist',
				name: 'SupplierPaylist',
				component: () => import("../appviews/supplier/Paylist.vue")
			},
		]
	},
	/* 采购(管理)端 */
	{ // 应用主页(子页面统一增加p-开头用于区分所属端)
		path: '/p-index',
		name: 'PurchaserIndex',
		component: () => import("../appviews/purchaser/Index.vue"),
		children: [
			{ // 首页
				path: '/p-home',
				name: 'PurchaserHome',
				component: () => import("../appviews/purchaser/Home.vue")
			},
			{ // 采购项目管理
				path: '/p-project',
				name: 'PurchaserProject',
				component: () => import("../appviews/purchaser/project.vue")
			},
			{ // 新建采购项目
				path: '/p-project-new',
				name: 'PurchaserProjectNew',
				component: () => import("../appviews/purchaser/projectNew.vue")
			},
			{ // 采购计划管理
				path: '/p-plan',
				name: 'PurchaserPlan',
				component: () => import("../appviews/purchaser/plan.vue")
			},
			{ // 新建采购计划
				path: '/p-plan-new',
				name: 'PurchaserPlanNew',
				component: () => import("../appviews/purchaser/planNew.vue")
			},
			{ // 新建询价单
				path: '/p-inquiry-new',
				name: 'PurchaserInquiryNew',
				component: () => import("../appviews/purchaser/inquiryNew.vue")
			},
			{ // 询价单管理
				path: '/p-inquiry',
				name: 'PurchaserInquiry',
				component: () => import("../appviews/purchaser/inquiry.vue")
			},
			{ // 统计汇总
				path: '/p-statistical',
				name: 'PurchaserStatistical',
				component: () => import("../appviews/purchaser/statistical.vue")
			},
			{ // 通知待办
				path: '/p-notice',
				name: 'PurchaserNotoce',
				component: () => import("../appviews/common/Notice.vue")
			},
			{ // 账号管理
				path: '/p-account',
				name: 'PurchaserAccount',
				component: () => import("../appviews/purchaser/account.vue")
			},
			{ // 专家管理
				path: '/p-specialist',
				name: 'PurchaserSpecialist',
				component: () => import("../appviews/purchaser/specialist.vue")
			},
			{ // 专家商详情
				path: '/p-specialist-detail',
				name: 'PurchaserSpecialistDetail',
				component: () => import("../appviews/purchaser/specialistDetail.vue")
			},
			{ // 供应商管理
				path: '/p-supplier',
				name: 'PurchaserSupplier',
				component: () => import("../appviews/purchaser/supplier.vue")
			},
			{ // 供应商详情
				path: '/p-supplier-detail',
				name: 'PurchaserSupplierDetail',
				component: () => import("../appviews/purchaser/supplierDetail.vue")
			},
			{ // 缴费管理
				path: '/p-paylist',
				name: 'PurchaserPaylist',
				component: () => import("../appviews/purchaser/paylist.vue")
			},
			{ // 组织机构
				path: '/p-organization',
				name: 'PurchaserOrganization',
				component: () => import("../appviews/purchaser/organization.vue")
			},
		]
	},
	/* 管理后台 */
	{ // 主页
		path: '/',
		name: 'Index',
		component: () => import("../views/Index.vue"),
		children: [
			// 框架组成模块
			{ // 首页
				path: '/home',
				name: 'Home',
				component: () => import("../views/modules/Home.vue")
			},
			{ // 消息管理
				path: '/message',
				name: 'Message',
				component: () => import("../views/modules/Message.vue")
			},
			{ // 账号管理
				path: '/account',
				name: 'Account',
				component: () => import("../views/modules/Account.vue")
			},
			{ // 密码管理
				path: '/passedit',
				name: 'Passedit',
				component: () => import("../views/modules/Passedit.vue")
			},
			
			// 系统设置模块
			{ // 菜单模块管理
				path: '/menu',
				name: 'Menu',
				component: () => import("../views/sys/Menu.vue")
			},
			{ // 角色权限管理
				path: '/role',
				name: 'Role',
				component: () => import("../views/sys/Role.vue")
			},
			{ // 平台用户管理
				path: '/user',
				name: 'User',
				component: () => import("../views/sys/User.vue")
			},
			{ // 参数配置管理
				path: '/config',
				name: 'Config',
				component: () => import("../views/sys/Config.vue")
			},
			{ // 操作日志查询
				path: '/logs',
				name: 'Logs',
				component: () => import("../views/sys/Logs.vue")
			},
			
			// 基础管理模块
			{ // 维度标签
				path: '/base-label',
				name: 'Label',
				component: () => import("../views/base/Label.vue")
			},
			{ // 专家
				path: '/base-specialist',
				name: 'Specialist',
				component: () => import("../views/base/Specialist.vue")
			},
			{ // 供应商
				path: '/base-supplier',
				name: 'Supplier',
				component: () => import("../views/base/Supplier.vue")
			},
			{ // 单位部门
				path: '/base-unit',
				name: 'Unit',
				component: () => import("../views/base/Unit.vue")
			},
			
			// 公共数据模块
			{ // 专家评审团队
				path: '/comm-auditteam',
				name: 'Auditteam',
				component: () => import("../views/comm/Auditteam.vue")
			},
			{ // 公示/通知
				path: '/comm-notice',
				name: 'Notice',
				component: () => import("../views/comm/Notice.vue")
			},
			{ // 人员档案
				path: '/comm-person',
				name: 'Person',
				component: () => import("../views/comm/Person.vue")
			},
			{ // 公共用户
				path: '/comm-user',
				name: 'CommUser',
				component: () => import("../views/comm/User.vue")
			},
			
			// 业务数据模块
			{ // 询价单
				path: '/data-inquire',
				name: 'Inquire',
				component: () => import("../views/data/Inquire.vue")
			},
			{ // 询价单子项
				path: '/data-inquire-item',
				name: 'InquireItem',
				component: () => import("../views/data/InquireItem.vue")
			},
			{ // 采购清单-物资采购计划
				path: '/data-inventory1',
				name: 'Inventory1',
				component: () => import("../views/data/Inventory1.vue")
			},
			{ // 采购清单-周转材料租赁计划
				path: '/data-inventory2',
				name: 'Inventory2',
				component: () => import("../views/data/Inventory2.vue")
			},
			{ // 采购清单-机械设备购置申请计划
				path: '/data-inventory3',
				name: 'Inventory3',
				component: () => import("../views/data/Inventory3.vue")
			},
			{ // 采购清单-机械设备租赁申请
				path: '/data-inventory4',
				name: 'Inventory4',
				component: () => import("../views/data/Inventory4.vue")
			},
			{ // 报价单
				path: '/data-offer',
				name: 'Offer',
				component: () => import("../views/data/Offer.vue")
			},
			{ // 采购计划
				path: '/data-plan',
				name: 'Plan',
				component: () => import("../views/data/Plan.vue")
			},
			{ // 采购项目
				path: '/data-project',
				name: 'Project',
				component: () => import("../views/data/Project.vue")
			},
			
			// 业务记录模块
			{ // 专家评审记录
				path: '/record-audit',
				name: 'Audit',
				component: () => import("../views/record/Audit.vue")
			},
			{ // 谈价记录
				path: '/record-bargain',
				name: 'Bargain',
				component: () => import("../views/record/Bargain.vue")
			},
		]
	}
]

export default createRouter({
	history: createWebHashHistory(),
	routes
})