import axios from 'axios'
/* 
		封装公共请求方法 
		@param method 请求方式，一般为GET/get、POST/post、PUT/put、DELETE/delete四种
		@param uri 请求接口uri地址(如果有地址栏参数，先拼接参数)
		@param params 请求参数(以data方式传输，没有请求参数传{})
		@param callback 请求回调方法，响应成功后回调
		@param header 自定义请求头(内部会加入token请求头)
*/
export default {
	API_ROOT: window.roots, // 公共api接口路径
	doRequest(method, uri, params, callback, header) {
		if(!uri.startsWith('/'))uri = '/' + uri;
		header = header || {};
		if (sessionStorage.token) {
			header.Authorization = 'Bearer ' + sessionStorage.token;
		}
		if (sessionStorage.xpageurl) {
			header['X-PAGE-URI'] = sessionStorage.xpageurl
		}
		if (method === 'GET' || method === 'get') {
			for (let key in params) {
				uri += (uri.indexOf('?') > 0 ? '&' : '?') + key + '=' + params[key];
			}
		}
		if (method === 'UPLOAD') {
			let formData = new FormData();
			formData.append('file', params);
			axios({
				url: this.API_ROOT + uri,
				method: 'POST',
				headers: {
					...header
				},
				data: formData
			}).then((response) => {
				let data = response.data;
				if (data.httpcode === 401) {
					sessionStorage.token = '';
					sessionStorage.userInfo = '';
					sessionStorage.roleList = '';
					location.replace('#/signin');
					return;
				}
				callback(data);
			}).catch(e => {
				console.log(e)
			});
		} else {
			axios({
				url: this.API_ROOT + uri,
				method: method,
				headers: {
					...header
				},
				data: {
					...params
				}
			}).then((response) => {
				let data = response.data;
				if (data.httpcode === 401) {
					sessionStorage.token = '';
					sessionStorage.userInfo = '';
					sessionStorage.roleList = '';
					location.replace('#/signin');
					return;
				}
				callback(data);
			}).catch(e => {
				console.log(e)
			});
		}
	},
	async doRequestAsync(method, uri, params, header) {
		if(!uri.startsWith('/'))uri = '/' + uri;
		header = header || {};
		params = params || {};
		if (sessionStorage.token) {
			header.Authorization = 'Bearer ' + sessionStorage.token;
		}
		if (sessionStorage.xpageurl) {
			header['X-PAGE-URI'] = sessionStorage.xpageurl
		}
		if (method === 'GET' || method === 'get') {
			for (let key in params) {
				uri += (uri.indexOf('?') > 0 ? '&' : '?') + key + '=' + params[key];
			}
		}
		const response = await axios({
				url: this.API_ROOT + uri,
				method: method,
				headers: {
					...header
				},
				data: {
					...params
				}
		});
		let data = response.data;
		if (data.httpcode === 401) {
			sessionStorage.token = '';
			sessionStorage.userInfo = '';
			sessionStorage.roleList = '';
			location.replace('#/signin');
			return;
		}
		return data;
	}
	
};