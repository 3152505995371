import { createStore } from 'vuex'

export default createStore({
	state: {
		userinfo: {
			nickname: '游客',
			ip: '0.0.0.0',
			lastd: '未登录',
		},
		tabdata: [
			{
				code: 'menu-0',
				name: '首页',
				link: '/home',
				closable: false
			}
		],
		rid: 0,
		roles: [],
		queryParams: [],
		permissions: {},
	},
	getters: {},
	mutations: {
		setUserInfo(state, data) {
			state.userinfo = data;
		},
		setRoles(state, data) {
			state.roles = data;
		},
	},
	actions: {},
	modules: {}
})
