import { createApp } from 'vue'
import App from './App.vue'
import router from './utils/router'
import store from './utils/store'
import G from './utils/globals'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './styles/base.css';
import './styles/anime.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import SQrcode from './components/SQrcode.vue'
import { debounce } from "lodash";
const resizeObserver  = (window).ResizeObserver;
(window).ResizeObserver = class ResizeObserver extends resizeObserver  {
  constructor(callback) {
    callback = debounce(callback, 100);
    super(callback);
  }
};

let vue = createApp(App);
vue.component('SQrcode', SQrcode);
vue.component('QuillEditor', QuillEditor);

vue.config.globalProperties.G = G;

vue.config.productionTip = false;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	vue.component(key, component)
}
vue.use(store).use(router).use(ElementPlus, {
	locale: zhCn,
}).mount('#app');
