import md5 from 'js-md5'
import stores from './store'
import api from '../utils/api'
import comm from '../utils/comm'
import msg from '../utils/message'
import router from '../utils/router'
import permissions from '../utils/permissions'
import { onMounted } from "vue"

const data = stores.state, tableHeight = 40 + 42.2167 * 15, defsize = 15, 
pagerSizes = [ 5, 10, 15, 20, 30, 50, 100 ], multrole = false, theme = {
	el_color_primary: '#1296db',
	el_color_primary_light_3: '#2aa8eb',
	el_color_primary_light_5: '#62c1f4',
	el_color_primary_light_7: '#7cc7ff',
	el_color_primary_light_8: '#a5d5ff',
	el_color_primary_light_9: '#bee0ff',
	el_color_primary_dark_2: '#0f81ba',
},
store = {
	get(key) {
		return localStorage[key];
	},
	set(key, value) {
		localStorage[key] = value;
	},
	rem(key) {
		delete localStorage[key];
	},
	remove(key) {
		delete localStorage[key];
	},
	gets(key) {
		return sessionStorage[key];
	},
	sets(key, value) {
		sessionStorage[key] = value;
	},
	rems(key) {
		delete sessionStorage[key];
	},
	removes(key) {
		delete sessionStorage[key];
	},
	local: {
		get(key) {
			return localStorage[key];
		},
		set(key, value) {
			localStorage[key] = value;
		},
		rem(key) {
			delete localStorage[key];
		},
		remove(key) {
			delete localStorage[key];
		},
	},
	session: {
		get(key) {
			return sessionStorage[key];
		},
		set(key, value) {
			sessionStorage[key] = value;
		},
		rem(key) {
			delete sessionStorage[key];
		},
		remove(key) {
			delete sessionStorage[key];
		},
	}
}, local = store.local, session = store.session;

router.load = router.open = router.navigate = uri => {
	router.push(uri);
}
router.refresh = () => {
	router.go();
	location.reload();
}
router.goBack = () => {
	router.back();
}
router.getCurrentPath = () => {
	return router.currentRoute.value.path;
}
router.getParam = name => {
	return data.queryParams[name] || '';
}
const getCurrentPath = () => {
	return router.currentRoute.value.path;
}
const getParam = name => {
	return data.queryParams[name] || '';
}
const auth = name => {
	let authed = permissions.auth(name, router, data);
	console.log('step4', authed);
	return authed;
}
/* 
	添加新标签页 
	@param that 调用组件的this对象
	@param code 标签页唯一编码
	@param name 标签页(唯一)名称
	@param link 标签页路由地址
*/
const addTabPage = (code, name, link, indexs) => {
	if (router) {
		sessionStorage.xpageurl = link;
		router.push(link);
	}
	let tabdata = data.tabdata, curtab = {
		code: code,
		name: name,
		link: link,
		indexs: indexs,
		closable: true
	};
	sessionStorage.curtab = JSON.stringify(curtab);
	for (let i = 0; i < tabdata.length; i++) {
		let item = tabdata[i];
		if (item.code == code) {
			data.curitem = code;
			return;
		}
	}
	data.tabdata.push(curtab);
	session.set('curerntMItem', indexs);
	session.set('tablists', JSON.stringify(data.tabdata));
	data.curitem = code;
}
/* 
	移除标签页
	@param that 调用组件的this对象
	@param code 标签页唯一编码
*/
const remTabPage = code => {
	let index = -1, pitem, remcur = code == data.curitem ? 1 : 0;
	if (!code) {
		code = data.curitem;
		remcur = 1;
	}
	let tabdata = data.tabdata;
	for (let i = 0; i < tabdata.length; i++) {
		let item = tabdata[i];
		if (item.code == code) {
			if (remcur == 1) pitem = tabdata[i - 1];
			index = i;
			break;
		}
	}
	if (index > -1) {
		data.tabdata.splice(index, 1);
		if (pitem) {
			session.set('curerntMItem', pitem.indexs);
			sessionStorage.curtab = JSON.stringify(pitem);
			sessionStorage.xpageurl = pitem.link;
			data.curitem = pitem.code;
			router.push(pitem.link);
		}
		session.set('tablists', JSON.stringify(data.tabdata));
	}
}
/* 
	点击标签页(激活并跳转到指定标签页)
	@param code 标签页唯一编码
*/
const clickTabItem = code => {
	let citem, tabdata = data.tabdata;
	for (let i = 0; i < tabdata.length; i++) {
		let item = tabdata[i];
		if (item.code == code) {
			citem = item;
			break;
		}
	}
	if (citem) {
		data.curitem = citem.code;
		sessionStorage.xpageurl = citem.link;
		sessionStorage.curtab = JSON.stringify(citem);
		session.set('curerntMItem', citem.indexs);
		router.push(citem.link);
	}
}
const dateFormat = (date, fmt = 'yyyy-MM-dd HH:mm:ss') => {
	if(!date)return '';
	if(typeof date == 'number')date = new Date(date);
	if(date instanceof Date) {
		fmt = fmt.replace('%Y', 'yyyy').replace('%m', 'MM').replace('%d', 'dd').replace('%T', 'HH:mm:ss')
				.replace('%h', 'hh').replace('%H', 'HH').replace('%i', 'mm').replace('%s', 'ss');
		let o = {
			"y+": this.getFullYear(),						 // 年份
			"y": this.getFullYear() % 100,			 // 年份
			"M+": this.getMonth() + 1,					 // 月份
			"M": this.getMonth() + 1,						 // 月份
			"d+": this.getDate(),								 // 日
			"d": this.getDate(),								 // 日
			"H+": this.getHours(),							 // 小时
			"H": this.getHours(),								 // 小时
			"h+": this.getHours() % 12,					 // 小时
			"h": this.getHours() % 12,					 // 小时
			"m+": this.getMinutes(),						 // 分
			"m": this.getMinutes(),							 // 分
			"s+": this.getSeconds(),						 // 秒
			"s": this.getSeconds(),							 // 秒
			"S+": this.getMilliseconds(),				 // 毫秒
			"S": this.getMilliseconds(),				 // 毫秒
			"E+": this.getDay(),								 // 星期
			"E": this.getDay(),									 // 星期
		}, WW = "星期日,星期一,星期二,星期三,星期四,星期五,星期六".split(','), ww = "周日,周一,周二,周三,周四,周五,周六".split(',');
		for (let k in o) {
			if (new RegExp("(" + k + ")").test(fmt)) {
				if (k == 'y+' || k == 'y') {
					fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 4 ? o[k] : o['y']);
				} else if (k == 'h+' || k == 'h') {
					fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1 ? o[k] : o[k] > 9 ? o[k] : '0' + o[k]));
					// fmt = fmt.replace(RegExp.$1, (Number(o['H+']) < 13 ? '上午 ' : '下午 ') + (RegExp.$1.length == 1 ? o[k] : o[k] > 9 ? o[k] : '0' + o[k]));
				} else if (k == 'S+' || k == 'S') {
					fmt = fmt.replace(RegExp.$1, o[k]);
				} else if (k == 'E+' || k == 'E') {
					fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? ww[o[k]] : WW[o[k]]);
				} else {
					fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
				}
			}
		}
		return fmt;
	}
}
/**
 * token自动登录
 * @param callback 登录成功的回调函数
 */
const tokenSignin = callback => {
	api.post('sys/token-signin', data => {
		if(data.rs) {
			callback(data.info);
		}
	})
}
/*
 * 注销当前登录的用户
*/
const signout = () => {
	api.del('sys/signout', data => {
		data.tabdata = [{
			code: 'menu-19',
			name: '首页',
			link: '/home',
			closable: false
		}];
		sessionStorage.token = '';
		sessionStorage.userInfo = '';
		sessionStorage.roleList = '';
		msg.warning(data.message);
		router.replace('/signin');
	});
}
const ready = fn => {
	onMounted(() => {
		data.queryParams = {};
		if(location.href.indexOf('?') > 0) {
			let querys = location.href.substring(location.href.indexOf('?') + 1), params = querys.split('&');
			for(let i = 0; i < params.length; i++) {
				let param = params[i].split('=');
				if(param.length > 1)data.queryParams[param[0]] = param[1];
			}
		}
		fn(data.queryParams);
	});
}
const _fns = fns => {
	
};
export default {
	tableHeight, defsize, pagerSizes, multrole, local, auth, 
	session, store, data, router, theme, comm, md5, msg, api, 
	addTabPage, remTabPage, clickTabItem, getCurrentPath, 
	getParam, dateFormat, tokenSignin, signout, ready, fns: _fns
}