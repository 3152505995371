import service from './service.js'
const auth = async(name, router, data) => {
	let uri = router.getCurrentPath().replace('/', ''), permissions = data.permissions[uri];
	if(!permissions) {
		let ret = await service.doRequestAsync('GET', 'sys/permission');
		console.log(typeof ret, ret);
		console.log(ret.rs, ret.permissions);
		if(ret.rs && ret.permissions) {
			permissions = ret.permissions;
			data.permissions[uri] = permissions;
		}
	}
	console.log('step1', permissions);
	if(!permissions)return false;
	console.log('step2', permissions);
	if(permissions[name] || permissions['#' + name])return true;
	console.log('step3', permissions);
	return false;
}
export default { auth }