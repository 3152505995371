import service from './service.js'

/**
 * uri请求查询参数拼接
 * @param {String} uri 请求URI
 * @param {Object} data 查询参数
 */
const uri = (uri, data) => {
	if(data) {
		uri += (uri.indexOf('?') > -1 ? '&' : '?');
		let params = Object.keys(data), query = "";
		params.map(param => {
			query += (query == '' ? '' : '&') + param + "=" + data[param]; 
		});
		uri += query;
	}
	return uri;
}
/**
 * url请求查询参数拼接
 * @param {String} uri 请求URI
 * @param {Object} data 查询参数
 */
const url = (curi, data) => {
	curi = curi.startsWith('/') ? curi : '/' + curi;
	return (window.roots || service.API_ROOT) + uri(curi, data);
}
/**
 * 获取站点配置信息
 */
const getSiteinfo = callback => {
	service.doRequest('GET', 'siteinfo', {}, callback);
}
/**
 * 通用get请求方法
 * @param {String} uri 请求URI
 * @param {Object/Function} arg1 请求数据或者回调方法
 * @param {Function} arg2 回调方法(有请求数据时)
 */
const get = (uri, arg1, arg2) => {
	let data = typeof arg1 == 'function' ? {} : arg1, callback = typeof arg1 == 'function' ? arg1 : arg2;
	service.doRequest('GET', uri, data, callback);
}
/**
 * 通用post请求方法
 * @param {String} uri 请求URI
 * @param {Object/Function} arg1 请求数据或者回调方法
 * @param {Function} arg2 回调方法(有请求数据时)
 */
const post = (uri, arg1, arg2) => {
	let data = typeof arg1 == 'function' ? {} : arg1, callback = typeof arg1 == 'function' ? arg1 : arg2;
	service.doRequest('POST', uri, data, callback);
}
/**
 * 通用put请求方法
 * @param {String} uri 请求URI
 * @param {Object/Function} arg1 请求数据或者回调方法
 * @param {Function} arg2 回调方法(有请求数据时)
 */
const put = (uri, arg1, arg2) => {
	let data = typeof arg1 == 'function' ? {} : arg1, callback = typeof arg1 == 'function' ? arg1 : arg2;
	service.doRequest('PUT', uri, data, callback);
}
/**
 * 通用delete请求方法
 * @param {String} uri 请求URI
 * @param {Object/Function} arg1 请求数据或者回调方法
 * @param {Function} arg2 回调方法(有请求数据时)
 */
const del = (uri, arg1, arg2) => {
	let data = typeof arg1 == 'function' ? {} : arg1, callback = typeof arg1 == 'function' ? arg1 : arg2;
	service.doRequest('DELETE', uri, data, callback);
}
/**
 * 通用load加载数据方法
 * @param {String} uri 请求URI
 * @param {Number} id 对应数据ID
 * @param {Object/Function} arg1 请求数据或者回调方法
 * @param {Function} arg2 回调方法(有请求数据时)
 */
const load = (uri, id, arg1, arg2) => {
	let data = typeof arg1 == 'function' ? {} : arg1, callback = typeof arg1 == 'function' ? arg1 : arg2;
	service.doRequest('GET', uri + (uri.endsWith('/') ? '' : '/') + id, data, callback);
}
/**
 * 通用save保存数据方法
 * @param {String} uri 请求URI
 * @param {Number} id 对应数据ID
 * @param {Object} data 请求数据
 * @param {Function} callback 回调方法(有请求数据时)
 */
const save = (uri, id, data, callback) => {
	if(id && id > 0)uri += (uri.endsWith('/') ? '' : '/') + id;
	service.doRequest(id && id > 0 ? 'PUT' : 'POST', uri, data, callback);
}
/**
 * 通用dele删除数据方法
 * @param {String} uri 请求URI
 * @param {Number} id 对应数据ID
 * @param {Object/Function} arg1 请求数据或者回调方法
 * @param {Function} arg2 回调方法(有请求数据时)
 */
const dele = (uri, id, arg1, arg2) => {
	let data = typeof arg1 == 'function' ? {} : arg1, callback = typeof arg1 == 'function' ? arg1 : arg2;
	uri += (uri.endsWith('/') ? '' : '/') +  id;
	service.doRequest('DELETE', uri, data, callback);
}
/**
 * 通用switch切换状态方法
 * @param {String} uri 请求URI
 * @param {Number} id 对应数据ID
 * @param {Object/Function} arg1 请求数据或者回调方法
 * @param {Function} arg2 回调方法(有请求数据时)
 */
const turn = (uri, id, arg1, arg2) => {
	let data = typeof arg1 == 'function' ? {} : arg1, callback = typeof arg1 == 'function' ? arg1 : arg2;
	uri += (uri.endsWith('/') ? '' : '/') + 'turn/' + id;
	service.doRequest('PUT', uri, data, callback);
}
/**
 * 通用list查询列表数据方法
 * @param {String} uri 请求URI
 * @param {Object/Function} arg1 请求数据或者回调方法
 * @param {Function} arg2 回调方法(有请求数据时)
 */
const list = (uri, arg1, arg2) => {
	let data = typeof arg1 == 'function' ? {} : arg1, callback = typeof arg1 == 'function' ? arg1 : arg2;
	service.doRequest('GET', uri, data, callback);
}
/**
 * 通用lists查询下拉数据方法
 * @param {String} uri 请求URI
 * @param {Object/Function} arg1 请求数据或者回调方法
 * @param {Function} arg2 回调方法(有请求数据时)
 */
const lists = (uri, arg1, arg2) => {
	let data = typeof arg1 == 'function' ? {} : arg1, callback = typeof arg1 == 'function' ? arg1 : arg2;
	uri += (uri.endsWith('/') ? '' : '/') + 'lists';
	service.doRequest('GET', uri, data, callback);
}
/**
 * 通用导入数据方法
 * @param {Object} uri 请求URI
 * @param {Object} file 导入数据文件对象
 * @param {Object} callback 回调方法
 */
const imports = (uri, file, callback) => {
	uri += (uri.indexOf('/import') > -1 ? '' : (uri.endsWith('/') ? '' : '/') + 'import');
	service.doRequest('UPLOAD', uri, file, callback);
}
const exports = (uri, data) => {
	uri += (uri.indexOf('/export') > -1 ? '' : (uri.endsWith('/') ? '' : '/') + 'export');
	window.open(url(uri, data));
}
/**
 * 通用上传方法
 * @param {String} uri 请求URI
 * @param {Object} file 上传文件对象
 * @param {Object} callback 回调方法
 */
const upload = (uri, file, callback) => {
	service.doRequest('UPLOAD', uri, file, callback);
}
/**
 * 通用下载方法
 * @param {Object} uri 请求URI
 * @param {Object} arg1 请求数据或者回调方法
 * @param {Object} arg2 回调方法(有请求数据时)
 */
const download = (uri, data) => {
	window.open(url(uri, data));
}
export default {
	roots: window.roots || service.API_ROOT,
	request: service.doRequest, uri, url,
	getSiteinfo, get, post, put, del,
	load, save, dele, turn, list, lists,
	upload, download, import: imports, export: exports
}